/**
 * NavToggle produces a nav container with a button
 * to toggle the display of the links.
 * Supported props: toplinks, sitename, fg, bg, ctx, currentpath, localizerfn
 */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import LinkMenu from './LinkMenu';
import logo from '../images/logo.png';
import LocaleSwitcher from './LocaleSwitcher';

export default function Nav(props) {
  const fgColor = (props.fg ? 'text-'+props.fg : '');
  const bgColor = (props.bg ? 'bg-'+props.bg : '');
  const navStyle = `container-fluid ${bgColor}`.trim();
  const localized = props.localizerfn;

  const [open, setOpen] = useState(false);
  const navLinks = <LinkMenu links={props.toplinks} listStyle={`nav-list ${fgColor}`} itemStyle={`nav-item ${fgColor}`} localizerfn={localized} />;

  // As workaround for bootstrap, hide an element given a min-height of 1px by making bg transparent
  const navLinkContainer = `row navbar-links${open?' open':''}`;

  function openMenu(e) {
    const isExpanded = !open;
    let targetElem = e.target;
    if (targetElem.tagName!=='BUTTON') {
      targetElem = targetElem.closest('BUTTON');
    }
    if (isExpanded) targetElem.classList.add('open');
    else            targetElem.classList.remove('open');
    setOpen(!open);
  }

  return (
    <nav className={navStyle}>
      <div className="pt-8 row">
        <div className="col-xs-6">
          <div id="logo">
            <Link to={localized('/')} title={props.sitename}>
              <img src={logo} alt={props.siteurl} className="img-responsive" />
            </Link>
          </div>
        </div>
        <div className="flr col-xs-6">
          <LocaleSwitcher ctx={props.ctx} currentpath={props.currentpath} rootStyle="col-xs-8" />
          <button className="navbar-btn flr col-xs-4" onClick={openMenu}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top"></span>
            <span className="icon-bar middle"></span>
            <span className="icon-bar bottom"></span>
          </button>
        </div>
      </div>
    </nav>
  );
};
