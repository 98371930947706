import React from 'react';

export default function Overlay ({header, text, links}) {
  return (
    <div className="overlay">
      <div className="overlay-inner">
        <p className="overlay-header">{header}</p>
        <div className="overlay-text-container">
          <p className="overlay-text">{text}</p>
        </div>
        <div className="overlay-button-container">
          <a className='btn-overlay' href={links[0].link}>{links[0].label}</a>
          <a className='btn-overlay inverted' href={links[1].link}>{links[1].label}</a>
        </div>
      </div>
    </div>
  )
}