/* Home page */

import React, {useReducer, useEffect} from 'react';
import { withPrefix, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

export default function Home(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.siteYaml;
  const [referrer, dispatch] = useReducer(setReferrer, props.location.search);

  useEffect(() => { dispatch(); }, [props.location.search]);

  function getReferrer() { return referrer; }
  function setReferrer(state, action) { if (Boolean(state)) return state; }

  return (
    <>
      <Layout config={sitedata} ctx={ctx} currentpath={props.path} referfn={getReferrer}>
        <Helmet htmlAttributes={{lang: ctx.locale}}>
          <meta charSet="utf-8" />
          <title>{sitedata.sitename}</title>
          <link rel="canonical" href={sitedata.siteurl} />
          <meta name="description" content={sitedata.metaDescription}></meta>
          <script src={withPrefix('fba.js')} />
          <script src={withPrefix('ggl.js')} />
        </Helmet>
      </Layout>
    </>
  );
};

export const query = graphql`
  query siteLocaleQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      homelink
      fixedTeaser
      fixedCta
      enrollstatus
      sitename
      siteurl
      startstudy
      topBanner
      topBannerLinkText
      subtitle
      surveyprompt
      title
      overlay {
        header
        text
        links {
          label
          link
        }
      }
      sections {
        bg
        fg
        vpad
        headerclass
        id
        linkto
        text
        data {
          content
          subhead
        }
        title
      }
      patientFields {
        address
        cta
        ctaDq
        email
        firstname
        lastname
        phone
        referredbyDefault
        referredby {
          id
          value
        }
      }
      navbar {
        bg
        fg
      }
      footer {
        links {
          label
          to
        }
        socialLinkLabel
        text
      }
      errorpage {
        header
        subheader
      }
      screener {
        questions {
          q
          proceedIfYes
          alias
          canSkip
          theme
          footer
        }
        questionLabel {
          affirm
          deny
          noun
          prep
        }
        successResult {
          header
          message
          thankyou {
            header
            subWLocation
            subheader
            text
          }
        }
        failResult {
          header
          message
          subheader
          thankyou {
            header
            subheader
            text
          }
        }
        locations {
          addresses {
            lat
            lng
            name
            street
            city
            state
            zip
            country
          }
          cta
          ctabutton
          header
          noSuitableSite
          subheader
        }
      }
    }
  }`;
