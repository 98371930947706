/**
 * Layout is a default page wrapper defining the major sections of the site.
 * Supported props: config, ctx, currentpath 
 */

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from './Banner';
import Nav from './NavToggle';
import BasicSection from './BasicSection';
import ComposedSection from './ComposedSection';
import pagetools from '../util/pagetools';
import { localizer } from '../util/localizer';
import Overlay from '../components/Overlay';

export default function Layout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = pagetools.makeTopLinks(cfg);
  const composed = <ComposedSection key={cfg.sections[0].id} src={cfg.sections[0]} />;
  const basicSections =  cfg.sections.slice(1).map((section, index) => <BasicSection key={section.id} src={section} /> );
  const imgPanel = <div className="-fluid" key="imgPanel">
        <StaticImage src='../images/man_with_tablet.jpg' alt="" className="mx-auto" />
  </div>;
  const imgPanel2 = <div className="-fluid" key="imgPanel2">
    <StaticImage src='../images/sitting_group.jpg' alt="" className="mx-auto" />
  </div>;
  basicSections.splice(0, 0, composed);
  basicSections.splice(1, 0, imgPanel);
  basicSections.splice(5, 0, imgPanel2);
  return (
    <div id="page">
      <div id="layout-root">
        <header>
          <Nav toplinks={toplinks}
              sitename={cfg.sitename}
              ctx={props.ctx}
              currentpath={props.currentpath}
              localizerfn={localized} />
          <div id="top-banner">
            {cfg.topBanner} <a href="https://clinicaltrials.gov/ct2/show/NCT05397470?cond=fulcrum+fshd&draw=2&rank=1" target="_blank" rel="noreferrer">{cfg.topBannerLinkText}</a>.
          </div>
          <Banner src={cfg} />
        </header>
      </div>
      <Overlay {...cfg.overlay} />
    </div>
  );
};
